.react-bootstrap-table-page-btns-ul{float:right;margin-top:0}.react-bootstrap-table-pagination-list-hidden{display:none}
/*
 * Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */
:root {

  /* Colors */
  --amazonOrange: #FF9900;
  --lightAmazonOrange: #FFAC31;
  --darkAmazonOrange: #E88B01;
  --squidInk: #232F3E;
  --lightSquidInk: #31465F;
  --deepSquidInk: #152939;
  --grey: #828282;
  --lightGrey: #C4C4C4;
  --silver: #E1E4EA;
  --darkBlue: #31465F;
  --red: #DD3F5B;
  --white: #FFFFFF;
  --light-blue: #00a1c9;
  

  /* Theme */
  --button-color: var(--white);
  --button-background-color: var(--amazonOrange);
  --button-click: var(--darkAmazonOrange);
  --link-color: var(--amazonOrange);
  --form-color: var(--white);
  --input-color: var(--deepSquidInk);
  --input-background-color: var(--white);

  --font-family: "Amazon Ember","Helvetica Neue Light","Helvetica Neue","Helvetica" ,"Arial","sans-serif";
  --body-background: #F8F4F4;

  /** Angular Theme **/
  --component-width-desktop: 460px;
  --component-width-mobile: 100%;

  --color-primary: #FF9900;
  --color-primary-accent: #232F3E;
  --color-primary-highlight: #FFC46D;
  
  --color-background:#232F3E;

  --color-secondary: #152939;
  --color-secondary-accent: #31465F;

  --color-danger: #DD3F5B;
  --color-error: #D0021B;

  --color-accent-brown: #828282;
  --color-accent-blue: #E1E4EA;

  --gradient-blaze: linear-gradient(270deg, #FFC300 0%, #FF9000 100%);

  --color-blue: #007EB9;
  --color-purple: #527FFF;
  --color-gray: #828282;
  --color-white: #FFFFFF;

  --input-border: 1px solid #C4C4C4;
  --input-padding: 0.5em 0.5em 0.3em 1em;

  --box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.15);
  --button-height: 42px;

  --interactions-conversation-height: 250px;

  /* Ionic Theme */
  
  /** primary **/
  --ion-color-primary: #FF9900;
  --ion-color-primary-rgb: 255,153,0;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #232F3E;
  --ion-color-primary-tint: #FFC46D;

  /** secondary **/
  --ion-color-secondary: #32db64;
  --ion-color-secondary-rgb: 50,219,100;
  --ion-color-secondary-contrast: #fff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #2cc158;
  --ion-color-secondary-tint: #47df74;

  /** tertiary **/
  --ion-color-tertiary: #f4a942;
  --ion-color-tertiary-rgb: 244,169,66;
  --ion-color-tertiary-contrast: #fff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #d7953a;
  --ion-color-tertiary-tint: #f5b255;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16,220,96;
  --ion-color-success-contrast: #fff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255,206,0;
  --ion-color-warning-contrast: #000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f53d3d;
  --ion-color-danger-rgb: 245,61,61;
  --ion-color-danger-contrast: #fff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #d83636;
  --ion-color-danger-tint: #f65050;

  /** light **/
  --ion-color-light: #f4f4f4;
  --ion-color-light-rgb: 244,244,244;
  --ion-color-light-contrast: #000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d7d7;
  --ion-color-light-tint: #f5f5f5;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;
  
  /** dark **/
  --ion-color-dark: #222;
  --ion-color-dark-rgb: 34,34,34;
  --ion-color-dark-contrast: #fff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e1e1e;
  --ion-color-dark-tint: #383838;
}
.Anchor__a___3JUCG {
  color: #FF9900;
  color: var(--link-color);
  cursor: pointer;
}

.Anchor__a___3JUCG:hover {
  text-decoration: underline;
}.Button__button___1FrBC {
  min-width: 153px;
  display: inline-block;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-image: none;
  color: #FFFFFF;
  color: var(--button-color);
  background-color: #FF9900;
  background-color: var(--button-background-color);
  border-color: #ccc;
  text-transform: uppercase;
  padding: 14px 0;
  letter-spacing: 1.1px;
  border: none;
}

.Button__button___1FrBC:active {
  opacity: 1;
  background-color: #E88B01;
  background-color: var(--button-click);
}

.Button__button___1FrBC:hover,
.Button__signInButton___2nUbs:hover {
  opacity: 0.8;
}

.Button__button___1FrBC:disabled {
  opacity: 1;
  cursor: auto;
  background-color: #FFC46D;
  background-color: var(--ion-color-primary-tint);
}

.Button__signInButton___2nUbs {
  position: relative;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 0;
  color: #152939;
  color: var(--deepSquidInk);
  font-size: 14px;
  box-sizing: content-box;
}

#Button__googleSignInButton___12y9G {
  background-color: #4285F4;
  font-family: Roboto;
  border: 1px solid #4285F4;
  color: #FFFFFF;
  color: var(--white);
}

#Button__googleSignInButton___12y9G > .Button__signInButtonIcon___341B9 {
  background-color: #FFFFFF;
  background-color: var(--white);
  border-radius: 4px 0 0 4px;
  height: 28px;
  width: 28px;
  padding: 12px;
}

#Button__auth0SignInButton___n_PKO {
  background-color: #eb5424;
  font-family: Roboto;
  border: 1px solid #e14615;
  color: #fff;
}

#Button__auth0SignInButton___n_PKO > .Button__signInButtonIcon___341B9 {
  border-radius: 4px 0 0 4px;
  height: 28px;
  width: 28px;
  padding: 12px;
  fill: #fff;
}

#Button__facebookSignInButton___1-zMC {
  background-color: #4267B2;
  border-color: #4267B2;
  font-family: "Helvetica Neue";
  color: #FFFFFF;
  color: var(--white);
}

#Button__facebookSignInButton___1-zMC > .Button__signInButtonIcon___341B9 {
  height: 33px;
  width: 18px;
  padding: 10px 14px;
}

#Button__amazonSignInButton___TmhOE {
  background-color: #FF9900;
  background-color: var(--amazonOrange);
  border: none;
  color: #FFFFFF;
  color: var(--white);
  font-family: "Amazon Ember";
}

#Button__amazonSignInButton___TmhOE > .Button__signInButtonIcon___341B9 {
  padding: 10px;
  height: 32px;
  width: 32px;
}

#Button__oAuthSignInButton___hREZG {
  background-color: #FFFFFF;
  background-color: var(--white);
  color: #152939;
  color: var(--deepSquidInk);
}

.Button__signInButtonIcon___341B9 {
  position: absolute;
  left: 0; 
}

.Button__signInButtonContent___2GM4s {
  text-align: center;
  display: block;
  padding: 18px 0;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}.Form__formContainer___cu04J {
  text-align: center;
  margin-top: 20px;
  margin: 5% auto 50px;
}

.Form__formSection___3tqxz {
  position: relative;
  margin-bottom: 20px;
  background-color: #FFFFFF;
  background-color: var(--form-color);
  padding: 35px 40px;
  text-align: left;
  display: inline-block;
  min-width: 460px;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.15);
  box-sizing: border-box;
}

.Form__formField___2DWhT {
  margin-bottom: 22px;
}

.Form__formRow___23vw6 {
  margin-bottom: 12px;
}

@media only screen and (max-width: 599px) {
  .Form__formContainer___cu04J {
    margin: 0;
  }

  .Form__formSection___3tqxz {
    width: 100%;
    box-sizing: border-box;
    padding: 35px 25px;
    box-shadow: none;
    border-radius: 0;
  }
}.Hint__hint___3Hrww {
  color: #828282;
  color: var(--grey);
  font-size: 12px;
}.Input__input___2Sh1s {
  display: block;
  width: 100%;
  padding: 16px;
  font-size: 14px;
  color: #152939;
  color: var(--input-color);
  background-color: #FFFFFF;
  background-color: var(--input-background-color);
  background-image: none;
  border: 1px solid #C4C4C4;
  border: 1px solid var(--lightGrey);
  border-radius: 3px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.Input__input___2Sh1s:disabled {
  color: #545454;
  background-color: #E1E4EA;
  background-color: var(--silver);
}

.Input__inputLabel___nQolz {
  color: #152939;
  color: var(--input-color);
  font-size: 14px;
  margin-bottom: 8px;
}

.Input__radio___1oPWf {
  margin-right: 18px;
  vertical-align: bottom;
}.Nav__navBar___3UdSD {
  position: relative;
  border: 1px solid transparent;
  border-color: #e7e7e7;
  background-color: #fff;
}

.Nav__navRight___2cUrh {
  text-align: right
}

.Nav__nav___17Pxn {
  padding: 10px;
}

.Nav__navItem___VnHJi {
  display: inline-block;
  padding: 10px 5px;
  line-height: 20px;
  margin-right: 12px;
}.PhotoPicker__photoPickerButton___A0pJW {
  width: 100%;
}

.PhotoPicker__photoPlaceholder___1YUHz {
  border: 2px dotted #828282;
  border: 2px dotted var(--grey);
  padding: 64px 0 64px;
}

.PhotoPicker__photoPlaceholderIcon___1eBTV {
  text-align: center;
  opacity: 0.2;
}.Section__container___1Gue_ {
  font-weight: 400;
}

.Section__actionRow___1hCHZ {
  margin-bottom: 15px;
}

.Section__sectionHeader___13iO4 {
  color: #152939;
  color: var(--deepSquidInk);
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 500;
}

.Section__sectionHeaderHint___16N_e {
  color: #828282;
  color: var(--grey);
  font-size: 16px;
  font-weight: 400;
  margin-top: 4px;
}

.Section__sectionBody___3DCrX {
  margin-bottom: 30px;
}

.Section__sectionFooter___1QlDn {
  font-size: 14px;
  color: #828282;
  color: var(--grey);
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
}

.Section__sectionFooterPrimaryContent___36Gb1 {
  margin-left: auto;
}

.Section__sectionFooterSecondaryContent___3cjOa {
  margin-right: auto;
  align-self: center;
}

@media only screen and (max-width: 599px) {
  .Section__sectionFooter___1QlDn {
    flex-wrap: wrap;
  }

  .Section__sectionFooterPrimaryContent___36Gb1 {
    width: 100%;
    margin-bottom: 32px;
  }

  .Section__sectionFooterPrimaryContent___36Gb1 > button {
    width: 100%;
  }

  .Section__sectionFooterSecondaryContent___3cjOa {
    text-align: center;
    flex: 0 0 100%;
  }
}.SelectInput__selectInput___1I_W8 {
  display: flex;
}

.SelectInput__selectInput___1I_W8 > input {
  flex: 1 1;
  border-radius: 0 3px 3px 0 !important;
}

.SelectInput__selectInput___1I_W8 > select {
  padding: 16px;
  font-size: 14px;
  color: #152939;
  color: var(--deepSquidInk);
  background-color: #fff;
  background-image: none;
  border: 1px solid #C4C4C4;
  border: 1px solid var(--lightGrey);
  border-right: none;
  border-radius: 3px 0 0 3px;
  box-sizing: border-box;
  margin-bottom: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  flex-basis: 22%;
  width: 1%;

  background-image:
    -webkit-linear-gradient(45deg, transparent 50%, gray 50%),
    -webkit-linear-gradient(315deg, gray 50%, transparent 50%),
    -webkit-linear-gradient(left, #ccc, #ccc);

  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 8px),
    calc(100% - 15px) calc(1em + 8px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    6px 5px,
    6px 5px,
    0px 1.5em;
  background-repeat: no-repeat;
}.Strike__strike___28OW3 {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #C4C4C4;
  border-bottom: 1px solid var(--lightGrey); 
  line-height: 0.1em;
  margin: 32px 0;
  color: #828282;
  color: var(--grey);
}

.Strike__strikeContent___1hd4F {
  background: #FFFFFF;
  background: var(--form-color); 
  padding: 0 25px;
  font-size: 14px;
  font-weight: 500;
}.Toast__toast___2YWKB {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
  padding: 16px;
  background-color: #31465F;
  background-color: var(--lightSquidInk);
  font-size: 14px;
  color: #fff;
  box-sizing: border-box;
}

.Toast__toast___2YWKB > span {
  margin-right: 10px;
}

.Toast__toastClose___2DQXs {
  margin-left: auto;
  align-self: center;
  position: relative;
  width: 18px;
  height: 18px;
  overflow: hidden;
  cursor: pointer;
}

.Toast__toastClose___2DQXs::before,
.Toast__toastClose___2DQXs::after {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #C4C4C4;
  background: var(--lightGrey);
}

.Toast__toastClose___2DQXs:hover::before,
.Toast__toastClose___2DQXs:hover::after {
  background: #DD3F5B;
  background: var(--red);
}

.Toast__toastClose___2DQXs::before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.Toast__toastClose___2DQXs::after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}.Totp__totpQrcode___3OH-m {
  text-align: center;
  margin-bottom: 30px;
}/*
 * Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

.XR__sumerianSceneContainer___11I4t {
  width: 100%;
  height: 100%;
  position: relative;
}

.XR__sumerianScene___3n6Vz {
  width: 100%;
  height: 100%;
}

.XR__loadingOverlay___1c9lj {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #31465F;
  background-color: var(--lightSquidInk);
}

.XR__loadingContainer___3C0lC {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.XR__loadingLogo___3mETr {
  margin-bottom: 20px;
  width: 80px;
  fill: #FFFFFF;
  fill: var(--white);
}

.XR__loadingSceneName___3JbEs {
  color: #FFFFFF;
  color: var(--white);
  margin: 0 2px 20px 2px;
  font-size: 18px;
  font-family: 'Amazon Ember';
}

.XR__loadingBar___QeOtl {
  height: 2px;
  width: 100%;
  border-radius: 2px;
  background-color: #152939;
  background-color: var(--deepSquidInk);
}

.XR__loadingBarFill___2Ej9- {
  background-color: #00a1c9;
  background-color: var(--light-blue);
  height: 100%;
  border-radius: 2px;
}

.XR__sceneErrorText___2PGJM {
  color: #DD3F5B;
  color: var(--red);
  font-size: 14px;
  font-family: 'Amazon Ember';
}

.XR__sceneBar___2jB64 {
  display: flex;
  align-items: center;
  font-size: 100%;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.XR__sceneName___3J8bY {
  color: #FFFFFF;
  color: var(--white);
  margin: 0 1.5em;
  font-size: 1em;
}

.XR__sceneActions___2a04n {
  margin-left: auto;
  margin-right: 16px;
  margin-bottom: 16px;
}

.XR__actionButton___7icN8 {
  position: relative;
  padding: 0;
  background: none;
  height: 54px;
  width: 54px;
  cursor:pointer;
  outline: none;
  text-decoration: none;
  border: none;
  border-radius: 30px;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  color: #FFFFFF;
  color: var(--white);
  fill: currentColor;
}

.XR__actionButton___7icN8:hover {
  background-color: #152939;
  background-color: var(--deepSquidInk);
  box-shadow: 0.3px 0.3px 0.3px rgba(0, 0, 0, 0.3);
}

.XR__actionButton___7icN8:hover > .XR__tooltip___3wuzm {
  display: block;
}

.XR__actionButton___7icN8:hover > svg {
  -webkit-filter: none;
          filter: none;
}

.XR__actionButton___7icN8:focus {
  outline: none;
}

.XR__actionButton___7icN8 > svg {
  width: 1.8em;
  height: 1.8em;
  -webkit-filter: drop-shadow(1px 1px 1px #828282);
          filter: drop-shadow(1px 1px 1px #828282);
  -webkit-filter: drop-shadow(1px 1px 1px var(--grey));
          filter: drop-shadow(1px 1px 1px var(--grey));
}

.XR__actionIcon___RjOmI {
  fill: #FFFFFF;
  fill: var(--white);
}

.XR__tooltip___3wuzm {
  display: inline;
  position: relative;
  font-size: 12px;
  font-family: 'Amazon Ember';
  margin: 0 0 0 16px;
}

.XR__autoShowTooltip___3QI-_ .XR__actionButton___7icN8 {
  background-color: #152939;
  background-color: var(--deepSquidInk);
}

.XR__tooltip___3wuzm:after {
  background-color: #152939;
  background-color: var(--deepSquidInk);
  border-radius: 2px;
  bottom: 46px;
  color: #FFFFFF;
  color: var(--white);
  content: attr(data-text);
  text-decoration: none;
  padding: 10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  position: absolute;
  white-space: nowrap;
  opacity: 0;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.XR__tooltip___3wuzm:before {
  border: solid;
  border-color: #152939 transparent transparent transparent;
  border-color: var(--deepSquidInk) transparent transparent transparent;
  border-width: 5px;
  bottom: 36px;
  content:"";
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  position: absolute;
  font-size: 14px;
  opacity: 0;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}


.XR__autoShowTooltip___3QI-_:after,
.XR__tooltip___3wuzm:hover:after {
  opacity: 1;
  -webkit-transition-delay: 1s;
          transition-delay: 1s;
}

.XR__autoShowTooltip___3QI-_:before,
.XR__tooltip___3wuzm:hover:before {
  opacity: 1;
  -webkit-transition-delay: 1s;
          transition-delay: 1s;
}
/*# sourceMappingURL=style.css.map*/
